import React, { useEffect, useState } from "react"

import "./LocationAccess.scss"
import { navigate } from "gatsby"

import Location from "../../assets/images/Icons/location-primary.svg"

const LocationAccess = () => {
  const [isLocationAccessDenied, setIsLocationAccessDenied] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const setLocation = (location) => {
    const locationObject = {
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    }

    localStorage.setItem("location", JSON.stringify(locationObject))
    navigate("/home")
  }

  const deniedAccess = () => {
    setIsLocationAccessDenied(true)
    localStorage.setItem("isAccessDenied", true)
  }

  const openLocationAccessPopup = () => {
    window.navigator.geolocation.getCurrentPosition(setLocation, deniedAccess)
  }

  useEffect(() => {
    const isLoctionProvided = !!JSON.parse(localStorage.getItem("location"))
    const isAccessDenied = JSON.parse(localStorage.getItem("isAccessDenied"))

    setIsLocationAccessDenied(isAccessDenied)
    if (isLoctionProvided) {
      navigate("/home")
      return
    }

    setIsMounted(true)
  }, [])

  useEffect(() =>
    setIsLocationAccessDenied(
      JSON.parse(localStorage.getItem("isAccessDenied"))
    )
  )

  if (!isMounted) {
    return <div></div>
  }

  return (
    <div className="location-access">
      <div className="location-access__container">
        <img src={Location} className="location-access__icon" />

        <p className="location-access__title">
          We need access to your location to proceed.
        </p>

        {!isLocationAccessDenied && (
          <button
            className="location-access__provide-access"
            onClick={openLocationAccessPopup}
          >
            Provide Access
          </button>
        )}

        {isLocationAccessDenied && (
          <p className="location-access__description">
            Use the iPad available in the location to place your order.
          </p>
        )}
      </div>
    </div>
  )
}

export default LocationAccess
