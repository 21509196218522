import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import LocationAccessDenied from "../components/Application/LocationAccess"

const LocationAccessPage = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("location"))) {
      navigate("/home")
      return
    }

    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return <LocationAccessDenied />
}

LocationAccessPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default LocationAccessPage
